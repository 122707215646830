@media only screen and (min-width: 768px) {
  .carusel {
    padding: 0 5%;
    background-color: #383e42;
    .height {
      filter: brightness(60%);
    }
  }
}
@media only screen and (min-width: 1340px) {
  .carusel {
    padding: 0 8%;
    .height {
      height: 650px;
      filter: brightness(60%);
    }
  }
}

@media only screen and (min-width: 1600px) {
  .carusel {
    padding: 0 10%;
    .height {
      height: 700px;
      filter: brightness(60%);
    }
  }
}

@media only screen and (min-width: 1600px) {
  .carusel {
    padding: 0 15%;
    .height {
      height: 750px;
      filter: brightness(60%);
    }
  }
}
@media only screen and (min-width: 1600px) {
  .carusel {
    padding: 0 15%;
    .height {
      height: 850px;
      filter: brightness(60%);
    }
  }
}
