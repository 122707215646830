@media only screen and (min-width:300px) {
    footer {
        background-color: #383e42;
        color: white;
        padding: 20px 0;
        .footer-container {
            text-align: center;
            .footer-social-media {
                padding-top: 10px;
                i {
                    margin: 0 10px;
                    padding-top: 20px;
                    cursor: pointer;
                }
            }
            .footer-contact {
                padding-top: 10px;
                p {
                    padding-top: 10px;
                    line-height: 0.5;
                }
            }
            .footer-program {
                padding-top: 10px;
                p {
                    padding-top: 10px;
                    line-height: 0.5;
                }
            }
        }
        .developer {
            text-align: center;
            font-size: 10px;
            padding-top: 10px;
            a {
                font-size: 14px;
                text-decoration: none;
                translate: 0.5s;
                color: white;
                cursor: pointer;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }
}
@media only screen and (min-width:768px) {
    footer {
        .footer-container {
            max-width: 768px;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            .footer-social-media {
                display: flex;
                flex-direction: column;
                a {
                    text-decoration: none;
                    color: white;
                    cursor: pointer;
                }

            }
        }
    }
}