@media only screen and (min-width:280px) {
    .nav-bar{
        display: none;
    }
}

@media only screen and (min-width:768px) {
    .nav-bar {
        display: flex;
        justify-content: space-between;
        padding: 1.2% 3%;
        background: whitesmoke;
        align-items: center;
        box-shadow: 0px 7px 28px 8px rgba(188, 188, 188, 0.74);
        z-index: 999;
        position: fixed;
        top: 0;
        width: 100%;
        a {
            color: #383e42;
            align-items: center;
            cursor: pointer;
            font-weight: 450;
            transition: 1s all;
            &:hover {
                transform: scale(1.2);
            }
        }
    }
    nav {
        a{  
            margin-right: 35px;
            color: #383e42;
            font-weight: 450;
            transition: 0.5s;
            &:hover {
                transform: scale(1.1);
            }
        }

    }
}
@media only screen and (min-width: 1900px) {
    .nav-bar {
        padding: 1% 2.5%;
    }
}
