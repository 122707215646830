.turqoise{
    color: rgb(8, 184, 8);
}

@media only screen and (min-width:300px) {
    .about-us {
        width: 100%;
        background-color: #383e42;
        padding: 15px 0;
        .gps-address {
            border-left: 1px solid white;
            border-right: 1px solid white;
            border-radius: 10px;
            padding: 0px 10px;
            width: 80%;
            margin: 0 auto;
            box-shadow: 0px 0px 30px 2px white;
            background-color: whitesmoke;
            h2 {
                text-align: center;
                color: #383e42;
            }
            p {
                color: #383e42;
                text-align: center;
                display: flex;
                flex-direction: column;
                line-height: 1.9;
                font-weight: bold;
            }
            a {
                padding: 2px 0;
                border-radius: 10px;
                border: none;
                background: rgb(8, 184, 8);
                text-align: center;
                margin: 20px 30%;
                color: white;
            }
        }
        .working-hours {
            width: 80%;
            margin: 0 auto;
            border-left: 1px solid white;
            border-right: 1px solid white;
            border-radius: 10px;
            box-shadow: 0px 0px 30px 2px white;
            background-color: whitesmoke;
            h2 {
                text-align: center;
                color: #383e42;
            }
            .lists {
                text-align: center;
                ul {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    li {

                        list-style-type: none;
                        font-weight: bold;
                        width: 100%;
                    }
                }

            }
        }
    }
}

@media only screen and (min-width:768px) {
    .about-us {
        display: flex;
        padding: 25px 10px 10px 10px;
        .gps-address {
            width: 40%;
            padding-top: 20px;
        }
        .working-hours {
            width: 40%;
            padding: 20px 0;
            .lists {
                ul {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    li {
                        list-style-type: none;
                        font-weight: bold;
                        width: 40%;
                    }
                }

            }
        }
    }
}

@media only screen and (min-width:1140px) {
    .about-us {
        padding: 25px 2%; 
        display: none;
    }
}


@media only screen and (min-width:1440px) {
    .about-us {
        padding: 25px 15%; 
    }
}


@media only screen and (min-width:2100px) {
    .about-us {
        padding: 25px 19%; 
    }
}

