@media only screen and (min-width:300px) {
    .service {
        background-color: #383e42;
        padding-bottom: 25px;
        .service-title{
            color: white;
            text-align: center;
            font-size: 38px;
            padding: 10px 0;
        }
        .card-box {
            display: grid;
            grid-template-columns: repeat(1, 2fr);
            background-color: white;
            max-width: 88%;
            margin: 0 auto;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 2px white;
            background-color: whitesmoke;
        }
    }
}

@media only screen and (min-width:768px) {
    .service {
        background-color: #383e42;
        padding-bottom: 25px;

        .card-box {
            grid-template-columns: repeat(2, 2fr);
            background-color: white;
            max-width: 88%;
            margin: 0 auto;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 2px white;
            background-color: whitesmoke;
        }
    }
}

@media only screen and (min-width:1340px) {
    .service {
        background-color: #383e42;
        padding-bottom: 25px;
        .card-box {
            grid-template-columns: repeat(3, 2fr);
            background-color: white;
            max-width: 80%;
            margin: 0 auto;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 2px white;
            background-color: whitesmoke;
        }
    }
}

@media only screen and (min-width:2300px) {
    .service {
        .card-box {
            grid-template-columns: repeat(4, 2fr);
        }
    }
}