@media only screen and (min-width:0) {
    .service-card {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 80%;
        height: 240px;
        border: 1px solid transparent;
        border-radius: 10px;
        margin: 20px auto 10px;
        overflow: hidden;
        box-shadow: 0px 0px 46px 3px rgba(56,62,66,0.75);
        background-color: whitesmoke;
        img {
            width: 100%;
            height: 130px;
            padding: 0 25px;
            margin: 0 auto;
            cursor: pointer;
        }
        h4 {
            text-align: center;
            padding-top: 10px;
        }
        button { 
            padding: 2px 0;
            border-radius: 10px;
            border: none;
            background: #383e42;
            text-align: center;
            margin: 0 50px;
            transition: 1s all;
            &:active {
                transform: scale(1.1);
            }
            i {
                color: white;
            }
        }
    }
}
@media only screen and (min-width:480px) {
    .service-card {
        width: 55%;
    }
}